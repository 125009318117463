<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <modal-status
          :status-data.sync="statusData"
          :status-options="taskStageOptions"
          :trigger="trigger"
          @refetch-data="refetchData"
        />
        <modal-log :log-data="logData" />
        <modal-details-remark
          :report-data.sync="reportData"
          @refetch-data="refetchData"
        />

        <b-modal
          ref="extend-modal"
          id="modal-center"
          centered
          title="Extend Expiration Date"
          hide-footer
        >
          <b-overlay :show="loadingNewDate" rounded="sm">
            <b-form-group
              :label="page + ' Expiration'"
              label-for="new_date"
              label-cols-md="6"
              class="required"
              style="padding: 20px 20px 0 20px"
            >
              <!-- <flat-pickr v-if="categoryFilter == 5" v-model="new_date" class="form-control" :config="{
                allowInput: false,
                dateFormat: 'Y',
                clickOpens: false,
                defaultDate: `${addThreeYears}`,
              }" placeholder="Enter new Expiration date" /> -->
              <b-form-input
                v-if="categoryFilter == 5"
                v-model="new_date"
                type="number"
                class="d-inline-block"
                placeholder="Enter Year"
              />
              <flat-pickr
                v-else
                v-model="new_date"
                class="form-control"
                :config="flatDateConfig"
                placeholder="Enter new Expiration date"
              />
              <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-3" variant="outline-secondary"
            @click="hideModal">
            Cancel
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-3" variant="outline-primary"
            @click="onSubmit">
            Submit
          </b-button> -->
              <div class="d-flex mt-2 justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  @click="onSubmit"
                >
                  <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                  Submit
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hideModal"
                >
                  Cancel
                </b-button>
              </div>
            </b-form-group>
          </b-overlay>
        </b-modal>

        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">{{ page }}</h3>
          <div class="form-col-select ml-2">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="Find Client"
            />
          </div>
        </div>
        <div
          class="d-flex flex-wrap flex-sm-nowrap justify-content-sm-start mt-1 mt-lg-0"
        >
          <b-button
            class="mr-sm-2 mobile-w100"
            variant="primary"
            @click="stageFilter = null"
          >
            <feather-icon icon="AlignJustifyIcon" class="mr-50" />
            All
          </b-button>
          <b-button
            class="mr-sm-2 mt-1 mt-sm-0 mobile-w100"
            variant="primary"
            @click="stageFilter = '1'"
          >
            <feather-icon icon="BookOpenIcon" class="mr-50" />
            Open
          </b-button>
          <b-button
            class="mr-sm-2 mt-1 mt-sm-0 mobile-w100"
            variant="primary"
            @click="stageFilter = '2'"
          >
            <feather-icon icon="LoaderIcon" class="mr-50" />
            In Progress
          </b-button>
          <b-button
            class="mt-1 mt-sm-0 mobile-w100"
            variant="primary"
            @click="stageFilter = '3'"
          >
            <feather-icon icon="CheckIcon" class="mr-50" />
            Completed
          </b-button>
        </div>
      </div>

      <b-table
        ref="refStaffWelcomeTable"
        class="position-relative"
        :items="fetchStaffWelcomeTask"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(records)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-if="data.item.records.length > 0"
            @click="openLogModal(data.item.records)"
          >
            <feather-icon icon="AlertCircleIcon" />
          </b-button>
        </template>

        <template #cell(task_stage_id)="data">
          <a
            href="javascript:void(0)"
            @click="handleChangeStatus(data.item)"
            class="d-flex align-items-center justify-content-start"
          >
            <b-badge
              :style="{
                background: data.item.task_stage.background_color,
                color: data.item.task_stage.font_color,
              }"
              size="18"
              class="badge_item mr-50"
            >
              <template v-if="data.item.task_stage_id == 1"> Open </template>
              <template v-if="data.item.task_stage_id == 2">
                In Progress
              </template>
              <template v-if="data.item.task_stage_id == 3">
                Complete
              </template>
            </b-badge>
          </a>
        </template>

        <template #cell(created_at)="data">
          {{ getCorrectDateTime(data.item.created_at, 1) }}
        </template>

        <template #head(pi_expiration_date)="data">
          <template v-if="categoryFilter == 1">Account Opening Date</template>
          <template v-if="categoryFilter == 2">Pi Expiration date</template>
          <template v-if="categoryFilter == 3"
            >Standing authority expiration date</template
          >
          <template v-if="categoryFilter == 4"
            >Discretionary expiration date</template
          >
          <template v-if="categoryFilter == 5">FATCA expiration date</template>
          <template v-if="categoryFilter == 9">Client's Birthday</template>
          <template v-if="categoryFilter == 10"
            >Client's Passport Expiry Date</template
          >
          <template v-if="categoryFilter == 11"
            >User's Passport Expiry Date</template
          >
        </template>

        <template #cell(pi_expiration_date)="data">
          <template v-if="categoryFilter == 1">{{
            data.item.open_date
          }}</template>
          <template v-if="categoryFilter == 2">{{
            data.item.pi_expiration_date
          }}</template>
          <template v-if="categoryFilter == 3">{{
            data.item.authority_expiration_date
          }}</template>
          <template v-if="categoryFilter == 4">{{
            data.item.authority_expiration_date
          }}</template>
          <template v-if="categoryFilter == 5">{{
            data.item.fatca_expiration_date
          }}</template>
          <template v-if="categoryFilter == 9">{{
            data.item.annual_return
          }}</template>
          <template v-if="categoryFilter == 10">{{
            data.item.client_details
              ? data.item.client_details.passport_expiry
              : data.item.controlling_persons.passport_expiry
          }}</template>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            class="action-trigger-btn"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="ability.can('read', 'actionlist')"
              :to="{ name: 'todo-detail', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="categoryFilter == 2 && ability.can('update', 'actionlist')"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-center
              variant="outline-primary"
              @click="task_id = data.item.id"
            >
              <feather-icon icon="CalendarIcon" />
              <span class="align-middle ml-50">Extend PI Status</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="categoryFilter == 3 && ability.can('update', 'actionlist')"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-center
              variant="outline-primary"
              @click="task_id = data.item.id"
            >
              <feather-icon icon="CalendarIcon" />
              <span class="align-middle ml-50">Extend Standing Authority</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="categoryFilter == 4 && ability.can('update', 'actionlist')"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-center
              variant="outline-primary"
              @click="task_id = data.item.id"
            >
              <feather-icon icon="CalendarIcon" />
              <span class="align-middle ml-50"
                >Extend Discretionary Authority</span
              >
            </b-dropdown-item>
            <b-dropdown-item
              v-if="categoryFilter == 5 && ability.can('update', 'actionlist')"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-center
              variant="outline-primary"
              @click="task_id = data.item.id"
            >
              <feather-icon icon="CalendarIcon" />
              <span class="align-middle ml-50">Extend FATCA Certification</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="ability.can('update', 'actionlist')"
              @click="openModalDetailsRemark(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update Remarks</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalStaffWelcomeTask"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BButtonGroup,
  BSidebar,
  VBToggle,
  VBModal,
  BOverlay,
  BForm,
  BFormGroup,
  BModal,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useStaffWelcomeList from "./useStaffWelcomeList";
import staffWelcomeStoreModule from "../staffWelcomeStoreModule";
import ModalStatus from "../../modal/ModalStatus.vue";
import ModalLog from "../../modal/ModalLog.vue";
import ModalDetailsRemark from "../../modal/ModalDetailsRemark.vue";
// import UserListAddNew from "./UserListAddNew.vue";
// import UserPasswordEdit from "./UserPasswordEdit.vue";
import flatPickr from "vue-flatpickr-component";
import ability from "@/libs/acl/ability";

export default {
  components: {
    // UserListAddNew,
    // UserPasswordEdit,
    flatPickr,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButtonGroup,
    ModalStatus,
    ModalLog,
    ModalDetailsRemark,
    vSelect,
    BSidebar,
    VBToggle,
    VBModal,
    BOverlay,
    BForm,
    BFormGroup,
    BModal,
    BCardText,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      page: "",
      statusData: {},
      reportData: {},
      logData: {},
      trigger: false,
      editDetails: "",
      new_date: "",
      task_id: "",
      addThreeYears: new Date().getFullYear() + 3,
      loadingNewDate: false,
    };
  },
  methods: {
    checkRoute(router_name) {
      if (router_name == "welcome-client") {
        this.categoryFilter = 1;
        this.page = "Welcome Letter";
      } else if (router_name == "pi") {
        this.categoryFilter = 2;
        this.page = "Professional Investor";
      } else if (router_name == "standing-instruction") {
        this.categoryFilter = 3;
        this.page = "Standing Instruction";
      } else if (router_name == "discretionary-renewal") {
        this.categoryFilter = 4;
        this.page = "Discretionary Renewal";
      } else if (router_name == "fatcha-certification") {
        this.categoryFilter = 5;
        this.page = "FATCA Certification";
      } else if (router_name == "client-birthday") {
        this.categoryFilter = 9;
        this.page = "Client Birthday";
      } else if (router_name == "client-passport-expiry") {
        this.categoryFilter = 10;
        this.page = "Client Passport Expiry";
      }
    },
    hideModal() {
      this.$refs["extend-modal"].hide();
    },
    handleEdit(id) {
      console.log(id);
      this.editDetails = id;
    },
    onSubmit() {
      console.log(this.new_date);
      this.loadingNewDate = true;
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let data = {
            id: this.task_id,
            task_category_id: this.categoryFilter,
            new_date: this.new_date,
          };
          console.log(data);
          this.loading = false;
          store
            .dispatch("app-user/updateExpirationDate", data)
            .then((response) => {
              this.loading = false;
              this.loadingNewDate = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
              this.hideModal();
            })
            .catch((error) => {
              this.loading = false;
              this.loadingNewDate = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleChangeStatus(item) {
      if (ability.can("update", "actionlist")) {
        this.statusData = { ...item };
        this.trigger = !this.trigger;
        this.$bvModal.show("modal-status");
      } else {
        this.$swal({
          text: "You don't have permission to update!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      }
    },
    openModalDetailsRemark(item) {
      this.reportData = { ...item };
      this.$bvModal.show("modal-details-remark");
    },
    openLogModal(item) {
      this.logData = { ...item };
      this.$bvModal.show("modal-logs");
    },
  },
  watch: {
    $route(to, from) {
      console.log("route", to, from);
      this.checkRoute(to.name);
    },
  },
  mounted() {
    let router_name = this.$router.currentRoute.name;
    this.checkRoute(router_name);
    console.log(router_name);
    console.log(this.categoryFilter);
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, staffWelcomeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const taskStageOptions = ref([]);
    const loading = ref(false);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptionList", { task_stage: true })
        .then((response) => {
          console.log(response);
          taskStageOptions.value = response.data.task_stages;
          console.log(taskStageOptions);
        })
        .catch((error) => {
          // if (error.response.status === 404) {
          taskStageOptions.value = undefined;
          console.log(error);
          // }
        });
    };

    onMounted(() => {
      fetchOptions();
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const {
      fetchStaffWelcomeTask,
      tableColumns,
      perPage,
      currentPage,
      totalStaffWelcomeTask,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStaffWelcomeTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      stageFilter,
      categoryFilter,
    } = useStaffWelcomeList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      loading,

      fetchStaffWelcomeTask,
      tableColumns,
      perPage,
      currentPage,
      totalStaffWelcomeTask,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStaffWelcomeTable,
      refetchData,
      ability,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      taskStageOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      stageFilter,
      categoryFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.badge_item {
  min-width: 100px;
  padding: 0.5rem;
}
</style>
