<template>
  <b-modal
    id="modal-status"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    scrollable
    title="CHANGE TASK STATUS"
    title-class="font-weight-bolder"
    body-class="common_modal modal_visible"
    hide-footer
    no-close-on-backdrop
    size="sm"
    centered
    hide-header-close
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form class="mt-sm-1" @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group
              label="Task Status"
              label-for="task_status_id"
              label-cols-md="3"
            >
              <div class="form-col-select">
                <v-select
                  id="task_status_id"
                  v-model="task_status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :clearable="false"
                  label="stage_name"
                  :reduce="(option) => option.id"
                  placeholder="Select Status"
                >
                  <template #option="{ background_color, stage_name }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :style="{ background: background_color }"
                      style="height: 10px; width: 10px"
                    />
                    <span> {{ stage_name }}</span>
                  </template>

                  <template #selected-option="{ background_color, stage_name }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :style="{ background: background_color }"
                      style="height: 10px; width: 10px"
                    />
                    <span> {{ stage_name }}</span>
                  </template>
                </v-select>

                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2 justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
            Submit
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCardBody,
  BCard,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    vSelect,
    BCard,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    trigger(newVal, oldVal) {
      this.task_status = this.statusData.task_stage.id;
    },
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-status");
    },

    onSubmit() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // this.handleUserSelection();
          let submitData = {
            id: this.statusData.id,
            task_stage_id: this.task_status,
          };
          this.loading = true;
          store
            .dispatch("app-user/updateStage", submitData)
            .then((response) => {
              this.loading = false;
              this.$emit("refetch-data");
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    trigger: {
      type: Boolean,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    statusData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      task_status: null,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

