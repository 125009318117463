<template>
  <b-modal
    id="modal-logs"
    cancel-variant="outline-secondary"
    scrollable
    size="lg"
    title="Logs"
  >
    <div class="py-1 px-2">
      <app-timeline>
        <app-timeline-item variant="secondary" icon="UserIcon"
          v-for="(item, index) in logData" :key="index"
        >
          <div
            class="
              d-flex
              flex-sm-row flex-column flex-wrap
              justify-content-between
              mb-1 mb-sm-0
            "
          >
          <h6 class="font-weight-bolder" v-if="item.user">{{item.user.name}}</h6>
          <small class="text-muted">{{item.update_date}}</small>
            <!-- <small class="text-muted">{{item.created_at.substring(0,10)}}</small> -->
          </div>
          <p v-html="item.remark" class="remark-content"></p>
        
        </app-timeline-item>
      </app-timeline>
    </div>
  </b-modal>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
export default {
  components: {
    BModal,
    VBModal,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    logData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
  .remark-content{
    white-space: pre;
  }
</style>